import appSettingsSaga from '../features/appSettings/appSettingsSaga';
import accountsSaga from './accountsSaga';
import appsSaga from './appsSaga';
import contactSaga from './contactSaga';
import initializationSaga from './initializationSaga';
import userFlowSaga from '../features/userFlow/userFlowSaga';
import userSaga from './userSaga';
import { all } from 'redux-saga/effects';
// import accountProfileSaga from '../features/accountProfile/accountProfileSaga';

export function* rootSaga() {
    yield all([
        // accountProfileSaga(),
        accountsSaga(),
        appSettingsSaga(),
        appsSaga(),
        contactSaga(),
        initializationSaga(),
        userFlowSaga(),
        userSaga(),
    ]);
}
