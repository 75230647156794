import React from 'react';
import config from '../config';
import { Link } from 'gatsby';

export const FooterEnterpriseContent: React.FC = () => (
    <p className='text-xs text-gray-500'>
        <span title={config.buildInfo}>© BioLib 2024</span>
        <span className='mx-2'>•</span>
        <Link to='/docs/' className='text-gray-500 hover:text-gray-200'>
            Docs
        </Link>
        <span className='mx-2'>•</span>
        <Link to='/contact/' className='text-gray-500 hover:text-gray-200'>
            Support
        </Link>
    </p>
)

const FooterEnterprise: React.FC = () => (
    <footer className='container my-4 pb-3'>
        <FooterEnterpriseContent/>
    </footer>
);

export default FooterEnterprise;
