import * as React from 'react';
import { Provider } from 'react-redux';
import reduxSaga from 'redux-saga';
import { rootSaga } from '../sagas';
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { accountsReducer } from './accounts/accountsReducer';
import { appsReducer } from './apps/appsReducer';
import appSettingsSlice from '../features/appSettings/appSettingsSlice';
import { contactReducer } from './contact/contactReducer';
import { notificationReducer } from './notification/notificationReducer';
import { userReducer } from './user/userReducer';
import userFlowSlice from '../features/userFlow/userFlowSlice';
import { baseApi } from '../api/baseApi';

const sagaMiddleware = reduxSaga();

export const store = configureStore({
    reducer: {
        [baseApi.reducerPath]: baseApi.reducer,

        // accountProfile: accountProfileSlice.reducer,
        accounts: accountsReducer,
        apps: appsReducer,
        appSettings: appSettingsSlice.reducer,
        contact: contactReducer,
        notification: notificationReducer,
        user: userReducer,
        userFlow: userFlowSlice.reducer,
    },
    middleware: (getDefaultMiddleware) => [
        ...getDefaultMiddleware(),
        // Adding the api middleware enables caching, invalidation, polling, and other useful features of `rtk-query`.
        baseApi.middleware,
        sagaMiddleware,
    ],
})

// Required for refetchOnFocus/refetchOnReconnect behaviors
setupListeners(store.dispatch);

sagaMiddleware.run(rootSaga);

export const WrapRootElementWithReduxProvider: React.FC<{ element: any }> = props => (
    <Provider store={store}>
        {props.element}
    </Provider>
);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export * from './common.types';
export * from './utilityFunctions';
