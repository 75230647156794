import * as React from 'react';
import BioLibLogo from '../images/logos/biolib_logo_color.svg';
import { FaFacebookF, FaGithub, FaLinkedinIn, FaSlack, FaTwitter } from 'react-icons/all';
import { Link } from 'gatsby';
import config from '../config';

const socialLinks = [
    { Icon: FaGithub, url: 'https://github.com/biolib' },
    { Icon: FaTwitter, url: 'https://twitter.com/biolib' },
    { Icon: FaFacebookF, url: 'https://facebook.com/biolib' },
    { Icon: FaLinkedinIn, url: 'https://linkedin.com/company/biolib' },
    { Icon: FaSlack, url: config.biolibCommunitySlackInviteUrl },
]

const contentLinks: Record<string, { text: string; path: string }[]> = {
    'Platform': [
        { text: 'Explore', path: '/explore/' },
        { text: 'Organizations', path: '/enterprise/' },
    ],
    'Support': [
        { text: 'Docs', path: '/docs/' },
        { text: 'Contact', path: '/contact/' },
    ],
}

export default class Footer extends React.Component {
    public render() {
        return (
            <footer className='mt-20 font-sans text-base'>
                <div className='container'>
                    <div className='flex flex-col md:flex-row'>
                        <div className='flex-auto md:max-w-xs'>
                            <img src={BioLibLogo} alt='BioLib Logo' className='h-8 md:mt-1' draggable={false}/>
                            <br/>
                            <p>The library of biological data science.</p>
                            <br/>
                        </div>
                        <div className='flex-auto'/>
                        <div className='flex-auto'>
                            <div className='flex flex-col md:flex-row md:float-right'>
                                {Object.entries(contentLinks).map(([title, content], index) => (
                                    <div key={index} className='flex-initial my-2 md:my-0 md:ml-24'>
                                        <h5 className='text-base font-bold uppercase text-cyan-800'>{title}</h5>
                                        <ul>
                                            {content.map(({ text, path }, index) => (
                                                <li key={index} className='my-2'>
                                                    <Link to={path} className='text-current'>{text}</Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className='flex flex-col md:flex-row items-center justify-between pb-6'>
                        <div className='flex-initial'>
                            <p className='mb-6 md:mb-0'>
                                <span title={config.buildInfo}>
                                    © BioLib 2024
                                </span>
                                <span className='mx-3'>·</span>
                                <Link to='/legal/terms/' className='text-current'>Terms</Link>
                                <span className='mx-3'>·</span>
                                <Link to='/legal/privacy-policy/' className='text-current'>Privacy</Link>
                                <span className='mx-3'>·</span>
                                <Link to='/careers/' className='text-current'>Careers</Link>
                            </p>
                        </div>
                        <div className='flex-initial flex flex-row md:float-right space-x-3'>
                            {socialLinks.map(({ Icon, url }, index) => (
                                <a
                                    key={index}
                                    href={url}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className='rounded h-7 w-7 flex items-center justify-center
                                    bg-gray-800 hover:bg-cyan-700 text-white hover:text-white text-lg'
                                >
                                    <Icon/>
                                </a>
                            ))}
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}
