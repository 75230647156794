import enterpriseReplace from './enterpriseReplace.json';

// GATSBY_ is important prefix for the environment variable to become available in the compiled JavaScript
const isEnterprise = (process.env.GATSBY_BIOLIB_IS_ENTERPRISE ?? '').toUpperCase() === 'TRUE';
const frontendVersion = process.env.GATSBY_BIOLIB_FRONTEND_VERSION ?? 'development';

const buildInfo = `${isEnterprise ? 'Enterprise ' : ''}Build v${frontendVersion}`;
const isDev = frontendVersion === 'development';

const baseURL: string = typeof window !== 'undefined' ? location.origin : '';
const domainForCookies: string = typeof window !== 'undefined' ? location.hostname : '';
const githubSsoClientId: string | null = null;
const isTeamSubdomain = false;

function isTeamSubdomainPage(locationFromComponentProps?: Location): boolean {
    const teamSubdomainPages = ['/client-routes/team-subdomain/', '/event/'];
    if (isEnterprise || (!locationFromComponentProps && typeof window === 'undefined')) {
        return false;
    }
    const location = locationFromComponentProps ? locationFromComponentProps : window.location;
    return isTeamSubdomain || (location && teamSubdomainPages.some((page) => location.pathname.startsWith(page)));
}

function isAuthenticatedPreBuiltPage(locationFromComponentProps?: Location): boolean {
    const authenticatedPages = ['/settings/', '/client-routes/authenticated/'];
    if (!locationFromComponentProps && typeof window === 'undefined') {
        return false;
    }
    const location = locationFromComponentProps ? locationFromComponentProps : window.location;
    return location && authenticatedPages.some((page) => location.pathname.startsWith(page));
}

enum localStorageKey {
    refreshToken = 'refreshToken',
    teamInvitation = 'teamInvitation',
}

const resourcePrefix = !isEnterprise ? '' : `@${enterpriseReplace.resourceHostnamePrefix}/`;

const allowedResourcePrefixes: string[] = [
    `%40${enterpriseReplace.resourceHostnamePrefix}`,
    `@${enterpriseReplace.resourceHostnamePrefix}`,
];

export default {
    allowedResourcePrefixes,
    baseURL,
    buildInfo,
    domainForCookies,
    frontendVersion,
    githubSsoClientId,
    isAuthenticatedPreBuiltPage,
    isDev,
    isEnterprise,
    isTeamSubdomain,
    isTeamSubdomainPage,
    localStorageKey,
    resourcePrefix,
    placeholderProfileImage: 'https://blbcdn.com/profile-pictures/default-profile-picture.png',
    biolibCommunitySlackInviteUrl:
        'https://join.slack.com/t/biolibcommunity/shared_invite/zt-k9m6xqbp-Gz84qaKL3Of8P7FRWQ3KIQ',
    githubSsoScope: 'user:email',
    itemsPerPage: 10,
    stackName: isEnterprise && enterpriseReplace.stackName ? enterpriseReplace.stackName : 'BioLib',
    sharingBaseUrl: isEnterprise && enterpriseReplace.sharingBaseUrl ? enterpriseReplace.sharingBaseUrl : baseURL,
    fileRendererHtmlPaths: {
        generic: process.env.GATSBY_BIOLIB_GENERIC_FILE_RENDERER_HTML_PATH,
        structure: process.env.GATSBY_BIOLIB_STRUCTURE_FILE_RENDERER_HTML_PATH,
    }
};
